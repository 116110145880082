import {ReactElement} from 'react';
import Icon from '../../../atoms/Icon/Icon';
import StyledActionButton from './styles';
import Props from './typings';

const ActionButton = ({
  active = false,
  disabled,
  onClick,
  size = 'default',
  title,
  icon,
  maxWidth,
  children,
}: Props): ReactElement => {
  return (
    <StyledActionButton
      aria-label={title ?? children}
      title={children ? undefined : title}
      aria-disabled={disabled}
      onClick={disabled ? undefined : onClick}
      $active={active}
      $maxWidth={maxWidth}
      $theme={children ? 'text' : size === 'default' ? 'icon' : 'smallIcon'}
    >
      {icon && <Icon type={icon} fontSize={size === 'small' ? 13 : 16} />}
      {children}
    </StyledActionButton>
  );
};

export default ActionButton;
