import styled, {css} from 'styled-components';
import {
  StyledProps,
  StyledListItemProps,
  StyledDropdownListContainerProps,
  StyledDropdownContainerProps,
} from './typings';
import colors from '../../../theme/constants/colors';
import Typography from '../../../theme/typography';
import zIndex from '../../../theme/constants/zIndex';
import focus from '@/theme/focus';
import scrollbar from '@/theme/elements/scrollbar';
import {sizePx} from '@/theme/helpers/size';
import typography from '../../../theme/typography';

const minPadding = {
  default: 16,
  tick: 60,
};

const dropdownBottomMargin = 20;

const headerStyle = {
  inline: {
    borderColor: 'transparent',
    borderRadius: '3px',
    padding: '0 15px',
  },
  button: {
    borderColor: colors.darkBlue100,
    borderRadius: '3px',
    padding: '0 15px',
  },
  icon: {
    borderColor: colors.darkBlue100,
    borderRadius: '50%',
    padding: '0 0 1px',
  },
};

const calcPadding = (componentPadding, tick) => {
  if (componentPadding) {
    if (tick) {
      return componentPadding > minPadding.tick ? componentPadding : minPadding.tick;
    } else {
      return componentPadding > minPadding.default ? componentPadding : minPadding.default;
    }
  } else {
    if (tick) {
      return minPadding.tick;
    } else {
      return minPadding.default;
    }
  }
};

export const StyledDropdownContainer = styled.div<StyledDropdownContainerProps>`
  width: ${(props) => (props.$wide ? 'auto' : 'fit-content')};
  position: relative;
`;

export const StyledDropdownHeader = styled.div<StyledProps>`
  ${(props) => css`
    text-transform: capitalize;
    white-space: nowrap;
    background: transparent;
    height: ${sizePx(23)};
    border: 1px solid ${headerStyle[props.$type].borderColor};
    border-radius: ${headerStyle[props.$type].borderRadius};
    color: ${colors.darkBlue100};
    ${props.$opened || props.$selected ? Typography.Subtitle2 : Typography.Body2}
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${headerStyle[props.$type].padding};
    cursor: pointer;
    ${props.$type === 'icon'
      ? css`
          width: ${sizePx(23)};
        `
      : css`
          max-width: fit-content;

          &::after {
            content: '';
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid ${colors.darkBlue100};
            margin-left: ${sizePx(5)};
            margin-top: ${sizePx(1)};
            transition: transform 0.2s ease-in;
            ${props.$opened &&
            css`
              transform: rotateZ(180deg);
              margin-top: 0;
            `}
          }
        `};
    ${props.$wide &&
    `
    max-width: 100%;
    justify-content: space-between;
    `}
    ${props.$opened ? focus.none : focus.brand()}
  `}
`;

export const StyledDropdownListContainer = styled.div<StyledDropdownListContainerProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${sizePx(6)};
  min-width: 100%;
  width: fit-content;
  max-width: calc(100vw - ${sizePx(20)});
  box-shadow: 0 0 ${sizePx(2)} rgba(0, 0, 0, 0.14);
  background: ${colors.white};
  border-radius: ${sizePx(2)};
  position: absolute;
  z-index: ${zIndex.dropdown};
  ${(props) => `
  ${props.$position}: 0;
  overflow: hidden;
  min-height: ${sizePx(132)};
  max-height: ${
    props.$maxHeight
      ? `${props.$maxHeight}px`
      : props.$calculatedHeight
      ? `calc(100vh - ${props.$calculatedHeight + dropdownBottomMargin}px)`
      : 'auto'
  };
  `}
`;

export const StyledDropdownList = styled.ul`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: ${colors.darkBlue100};
  ${Typography.Body2}
  overflow: hidden auto;
  ${scrollbar.secondary}
`;

export const StyledListItem = styled.li<StyledListItemProps>`
  ${(props) => css`
    box-sizing: border-box;
    list-style: none;
    cursor: pointer;
    padding: ${sizePx(8)} ${calcPadding(props.$rightPadding, props.$tick)}px ${sizePx(8)}
      ${sizePx(10)};
    position: relative;
    white-space: nowrap;
    width: 100%;
    ${props.$selected &&
    css`
      font-weight: bold;

      ${props.$tick &&
      `&::after {
        content: '';
        position: absolute;
        top: ${sizePx(9)};
        right: ${sizePx(10)};
        width: ${sizePx(9)};
        height: ${sizePx(5)};
        border-bottom: 3px solid ${colors.red100};
        border-left: 3px solid ${colors.red100};
        background: transparent;
        transform: rotateZ(-45deg);
      }
      `}
    `}
    ${props.$hovered &&
    css`
      background-color: ${colors.grey25};
    `}
  `}
`;

export const StyledSearchContainer = styled.div`
  display: flex;
  flex-direction: column;

  input {
    border: none;
    border-bottom: 1px solid ${colors.darkBlue25};
    margin: ${sizePx(10)} ${sizePx(10)} 0;
    ${Typography.Body2}
    border-radius: 0;

    &:focus {
      outline: none;
      border-bottom-color: ${colors.darkBlue100};
      border-radius: 0;
    }
  }
`;

export const StyledNoSearchResultsMsg = styled.p`
  ${typography.Body1}
  color: ${colors.darkBlue75};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${sizePx(30)};
`;

export const StyledSearchCounter = styled.p`
  ${typography.Body3}
  color: ${colors.darkBlue50};
  align-self: flex-end;
  margin-right: ${sizePx(10)};
`;
