import React, {ReactElement} from 'react';
import StyledActionText from './styles';
import Props from './typings';

const ActionText = ({fontSize = 16, disabled = false, onClick, children}: Props): ReactElement => {
  return (
    <StyledActionText $fontSize={fontSize} onClick={onClick} $disabled={disabled} tabIndex={0}>
      {children}
    </StyledActionText>
  );
};

export default ActionText;
