import FacebookIcon from './Facebook/FacebookIcon';
import InstagramIcon from './Instagram/InstagramIcon';
import PinterestIcon from './Pinterest/PinterestIcon';
import TwitterIcon from './Twitter/TwitterIcon';
import {StyledSocialIcons} from './styles';
import Props from './typings';

const SocialIcons = ({size = 16, defaultColor, hoverColor}: Props) => {
  return (
    <StyledSocialIcons $defaultColor={defaultColor} $hoverColor={hoverColor}>
      <FacebookIcon size={size} />
      <TwitterIcon size={size} />
      <InstagramIcon size={size} />
      <PinterestIcon size={size} />
    </StyledSocialIcons>
  );
};

export default SocialIcons;
