import Link from 'next/link';

import ActionText from '@/atoms/ActionText/ActionText';
import CtaButton from '@/atoms/Buttons/CTA/CtaButton';
import Icon from '@/atoms/Icon/Icon';
import NotificationWrapper from '@/atoms/Wrappers/Notification/NotificationWrapper';
import {emit} from '@/utils/events';

import constantsFactory from '@/utils/constants';
import {useState} from 'react';
import {
  StyledCreditAmount,
  StyledCreditLink,
  StyledProfileMenu,
  StyledProfileMenuContainer,
  StyledProfileMenuIcon,
} from './styles';
import Props, {CreditProps} from './typings';

const {DATA_TEST_ID} = constantsFactory();

const Credit = ({credit}: CreditProps) => {
  const hasCredit = () => {
    if (!credit.display_text) return false;

    return !credit.display_text.toLowerCase().includes('earn');
  };

  return (
    <>
      {credit.url && (
        <StyledCreditLink href={credit.url} aria-label="Your Credits">
          <Icon type={'arrow-right'} fontSize={9} />

          <Icon type={'card'} fontSize={17} />

          {hasCredit() && (
            <>
              {'You have'}
              <br />
            </>
          )}

          <StyledCreditAmount>{credit.display_text}</StyledCreditAmount>
        </StyledCreditLink>
      )}
    </>
  );
};

const ProfileMenu = ({auth, userSettings, allowDropdown = true}: Props) => {
  const [open, setOpen] = useState(false);

  const loggedOutMenu = () => (
    <>
      <ActionText
        onClick={(e) => {
          e.preventDefault();
          emit('CLICK_ON_LOGIN');
        }}
      >
        Log In
      </ActionText>
      <span>- or -</span>
      <CtaButton
        onClick={(e) => {
          e.preventDefault();
          emit('CLICK_ON_JOIN');
        }}
        maxWidth="164px"
      >
        Sign Up
      </CtaButton>
    </>
  );

  const loggedInMenu = () => {
    const loggedInLinks = [
      {
        component: <Credit credit={userSettings.credit_data} />,
        condition: !!userSettings.credit_data.display_text,
      },
      {
        text: 'Profile',
        href: '/u/',
      },
      {
        text: 'Favourites',
        href: '/u/favourites/',
      },
      {
        text: 'Orders',
        href: '/u/orders/',
      },
      {
        component: (
          <NotificationWrapper count={userSettings.review_count} position={'side'}>
            Orders Awaiting Review
          </NotificationWrapper>
        ),
        label: `Orders Awaiting Review - ${userSettings.review_count} New`,
        href: '/u/orders/',
        condition: userSettings.review_count > 0,
      },
      {
        component: (
          <NotificationWrapper count={userSettings.unread_count} position={'side'}>
            Messages
          </NotificationWrapper>
        ),
        label: `Messages${
          userSettings.unread_count > 0 ? ` - ${userSettings.unread_count} New` : ''
        }`,
        href: '/messages/inbox/',
      },
      {
        text: 'Account Settings',
        href: '/u/settings/',
      },
      {
        text: 'Privacy Center',
        href: '/u/privacy/',
      },
      {
        text: 'Invite Friends',
        href: '/invite/',
      },
      {
        text: 'Log Out',
        action: auth.logout,
      },
    ];

    return (
      <ul>
        {loggedInLinks.map((link, key) => {
          const content = link.component ?? link.text;

          return (
            <>
              {(link.condition ?? true) && (
                <li key={key}>
                  {link.href ? (
                    <Link href={link.href} aria-label={link.label ?? link.text}>
                      {content}
                    </Link>
                  ) : (
                    <span aria-label={link.text} onClick={link.action}>
                      {content}
                    </span>
                  )}
                </li>
              )}
            </>
          );
        })}
      </ul>
    );
  };

  return (
    <StyledProfileMenuContainer
      onMouseEnter={() => {
        if (allowDropdown) setOpen(true);
      }}
      onMouseLeave={() => {
        setOpen(false);
      }}
    >
      <StyledProfileMenuIcon href={'/u/'} aria-label="Your Profile">
        <NotificationWrapper count={userSettings.unread_count + userSettings.review_count}>
          <Icon fontSize={19} type={auth.isAuthenticated() ? 'profile' : 'profile-outlined'} />
        </NotificationWrapper>
      </StyledProfileMenuIcon>

      {open && (
        <StyledProfileMenu
          $loggedIn={auth.isAuthenticated()}
          data-testid={DATA_TEST_ID.PROFILE_MENU}
        >
          {auth.isAuthenticated() ? loggedInMenu() : loggedOutMenu()}
        </StyledProfileMenu>
      )}
    </StyledProfileMenuContainer>
  );
};

export default ProfileMenu;
