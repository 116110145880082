/**
 * @description Extracts the slug from a URL or a pathname
 * @example
 * const url = '/posts/123/comments';
 * const pattern = '/posts/:slug/comments';
 * const slug = getSlugFromUrl(url, pattern); // {slug: '123'}
 * @example
 * const url = '/posts/123/comments/456';
 * const pattern = '/posts/:postId/comments/:commentId';
 * const slugs = getSlugFromUrl(url, pattern); // {postId: '123', commentId: '456'}
 * @example
 * const url = '/posts/456';
 * const slugs = getSlugFromUrl(url); // {slug: '456'}
 *
 * @param {string} url a URL or a pathname that includes the slug
 * @param {string?} pattern a optional pathname pattern that shows where the slug is
 * @return {Record<string, string>} slug(s) extracted from the URL
 */
export const getSlugFromUrl = (url: string, pattern?: string) => {
  const [withoutQueryParams] = url.split('?');
  const urlParts = withoutQueryParams.split('/').filter((part) => !!part);

  /**
   * If the pattern is not provided or does not include a colon,
   * return the last part of the URL
   */
  if (!pattern || !pattern.includes(':')) {
    return {slug: urlParts[urlParts.length - 1]};
  }

  const patternParts = pattern.split('/').filter((part) => !!part);
  /**
   * If the pattern and URL parts do not match,
   * return the last part of the URL
   */
  if (patternParts.length !== urlParts.length) {
    return {slug: urlParts[urlParts.length - 1]};
  }

  return patternParts.reduce((slugsMap, part, index) => {
    if (part.startsWith(':')) {
      const slugName = part.slice(1);
      slugsMap[slugName] = urlParts[index];
    }

    return slugsMap;
  }, {} as Record<string, string>);
};
