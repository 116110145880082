import {css} from 'styled-components';
import pxToRem from './helpers/pxToRem';
import breakpoint from './constants/breakpoints';

const generalStyling = (font: string, size: number, weight: string, lineHeight: number) => css`
  font-family: var(--${font}, ${font}), helvetica, sans-serif;
  font-size: ${pxToRem(size)};
  font-weight: ${weight};
  line-height: ${pxToRem(lineHeight)};
`;

const Hero = css`
  ${generalStyling('boing', 36, 'normal', 38)}

  @media only screen and ${breakpoint.device.s} {
    font-size: ${pxToRem(48)};
    line-height: ${pxToRem(50)};
  }

  @media only screen and ${breakpoint.device.l} {
    font-size: ${pxToRem(60)};
    line-height: ${pxToRem(62)};
  }
`;

const Main = css`
  ${generalStyling('boing', 30, 'normal', 32)}

  @media only screen and ${breakpoint.device.s} {
    font-size: ${pxToRem(38)};
    line-height: ${pxToRem(40)};
  }

  @media only screen and ${breakpoint.device.l} {
    font-size: ${pxToRem(48)};
    line-height: ${pxToRem(50)};
  }
`;

const Section = css`
  ${generalStyling('boing', 24, 'normal', 26)}

  @media only screen and ${breakpoint.device.l} {
    font-size: ${pxToRem(32)};
    line-height: ${pxToRem(35)};
  }
`;

const Title = css`
  ${generalStyling('boing', 20, 'normal', 22)}

  @media only screen and ${breakpoint.device.l} {
    font-size: ${pxToRem(26)};
    line-height: ${pxToRem(28)};
  }
`;

const Highlights = css`
  ${generalStyling('boing', 16, 'normal', 18)}

  @media only screen and ${breakpoint.device.l} {
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(25)};
  }
`;

const artVariations = (weight) => css`
  ${generalStyling('sul-sans', 18, weight, 20)}

  @media only screen and ${breakpoint.device.l} {
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(25)};
  }
`;

const Art = css`
  ${artVariations('bold')}
`;

const Headline = css`
  ${artVariations('normal')}
`;

const Subtitle1 = generalStyling('sul-sans', 16, 'bold', 26);

const Subtitle2 = generalStyling('sul-sans', 14, 'bold', 22);

const Subtitle3 = generalStyling('sul-sans', 12, 'bold', 20);

const Body1 = generalStyling('sul-sans', 16, 'normal', 26);

const Body2 = generalStyling('sul-sans', 14, 'normal', 22);

const Body3 = generalStyling('sul-sans', 12, 'normal', 20);

export default {
  Hero,
  Main,
  Section,
  Title,
  Highlights,
  Art,
  Headline,
  Subtitle1,
  Subtitle2,
  Subtitle3,
  Body1,
  Body2,
  Body3,
};
