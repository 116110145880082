export const newKeyboardEvent = (e: React.KeyboardEvent, eventType = 'keydown') => {
  const event = new KeyboardEvent(eventType, {
    key: e.key,
    code: e.code,
    keyCode: e.keyCode,
    charCode: e.charCode,
    which: e.which,
    bubbles: true, // Important for the event to bubble up
    cancelable: true,
  });
  return event;
};
