'use client';
import styled, {css} from 'styled-components';
import type {StyledProps} from './typings';
import colors from '@/theme/constants/colors';
import button from '@/theme/constants/button';
import pxToRem from '@/theme/helpers/pxToRem';
import focus from '@/theme/focus';

const textStroke = (width, color) => css`
  text-stroke: ${width}px ${color};
  -webkit-text-stroke: ${width}px ${color};
`;

const textStrokeColor = (color) => css`
  text-stroke-color: ${color};
  -webkit-text-stroke-color: ${color};
`;

const defaultTheme = {
  height: button.height,
  color: {
    default: {
      border: colors.darkBlue100,
      color: colors.darkBlue100,
      icon: {
        stroke: colors.darkBlue100,
        fill: 'transparent',
      },
    },
    hover: {
      border: colors.red100,
      color: colors.red100,
      icon: {
        stroke: colors.red100,
        fill: 'transparent',
      },
    },
    active: {
      border: colors.red100,
      color: colors.red100,
      icon: {
        stroke: 'transparent',
        fill: colors.red100,
      },
    },
    disabled: {
      border: colors.darkBlue25,
      color: colors.darkBlue25,
      icon: {
        stroke: colors.darkBlue25,
        fill: 'transparent',
      },
    },
  },
};

const theme = {
  text: defaultTheme,
  icon: defaultTheme,
  smallIcon: {
    height: 32,
    color: {
      default: {
        border: colors.grey100,
        color: 'transparent',
        icon: {
          stroke: 'transparent',
          fill: colors.darkBlue100,
        },
      },
      hover: {
        border: colors.grey100,
        color: 'transparent',
        icon: {
          stroke: 'transparent',
          fill: colors.red100,
        },
      },
      active: {
        border: colors.red100,
        color: 'transparent',
        icon: {
          stroke: 'transparent',
          fill: colors.red100,
        },
      },
      disabled: {
        border: colors.darkBlue25,
        color: 'transparent',
        icon: {
          stroke: colors.darkBlue25,
          fill: 'transparent',
        },
      },
    },
  },
};

const StyledActionButton = styled.button<StyledProps>`
  ${(props) => css`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-weight: bold;
    font-size: ${pxToRem(button.fontSize)};
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    background: transparent;
    height: ${theme[props.$theme].height}px;
    width: ${props.$theme === 'text' ? 'auto' : `${theme[props.$theme].height}px`};
    padding: ${pxToRem(button.paddingY)} ${props.$theme === 'text' ? pxToRem(button.paddingX) : 0};
    border: 1px solid ${theme[props.$theme].color.default.border};
    border-radius: ${theme[props.$theme].height}px;
    color: ${theme[props.$theme].color.default.color};
    flex-shrink: 0;

    ${props.$maxWidth &&
    css`
      width: 100%;
      max-width: ${props.$maxWidth};
    `}

    [class^='icon-'],
    [class*=' icon-'] {
      ${textStroke(1, theme[props.$theme].color.default.icon.stroke)}
      color: ${theme[props.$theme].color.default.icon.fill};
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        border-color: ${theme[props.$theme].color.hover.border};
        color: ${theme[props.$theme].color.hover.color};

        [class^='icon-'],
        [class*=' icon-'] {
          ${textStrokeColor(theme[props.$theme].color.hover.icon.stroke)};
          color: ${theme[props.$theme].color.hover.icon.fill};
        }
      }
    }

    &:active {
      border-color: ${theme[props.$theme].color.active.border};
      color: ${theme[props.$theme].color.active.color};

      [class^='icon-'],
      [class*=' icon-'] {
        color: ${theme[props.$theme].color.active.icon.fill};
        ${textStrokeColor(theme[props.$theme].color.active.icon.stroke)};
      }
    }

    ${props.$active &&
    css`
      border-color: ${theme[props.$theme].color.active.border};
      color: ${theme[props.$theme].color.active.color};

      [class^='icon-'],
      [class*=' icon-'] {
        color: ${theme[props.$theme].color.active.icon.fill};
        ${textStrokeColor(theme[props.$theme].color.active.icon.stroke)};
      }
    `}

    ${focus.brand()}

    &[aria-disabled='true'] {
      color: ${theme[props.$theme].color.disabled.color};
      border-color: ${theme[props.$theme].color.disabled.border};
      pointer-events: none;

      [class^='icon-'],
      [class*=' icon-'] {
        color: ${theme[props.$theme].color.disabled.icon.fill};
        ${textStrokeColor(theme[props.$theme].color.disabled.icon.stroke)};
      }
    }
  `}
`;

export default StyledActionButton;
