import React from 'react';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import configs from '../../../../../utils/configs';
import CtaButton from '../../../../atoms/Buttons/CTA/CtaButton';
import ListDropdown from '../../../Dropdowns/List/ListDropdown';
import {saveUserSettings} from '../../../../../../redux/actions/settingsActions';
import {
  StyledButtonContainer,
  StyledCancelButton,
  StyledErrorMsg,
  StyledUserSettings,
} from './styles';
import {Props} from './typings';

const UserSettings = ({userSettings, closeCallback}: Props) => {
  const dispatch = useDispatch();
  const {i18n} = useTranslation();
  const [currency, setCurrency] = useState(userSettings.currency);
  const [country, setCountry] = useState(userSettings.country);
  const [units, setUnits] = useState(userSettings.display_units);
  const [language, setLanguage] = useState(userSettings.language);
  const [error, setError] = useState<null | string>(null);
  const [disableBtns, setDisableBtns] = useState(false);

  const isIdentical = () => {
    let identical = true;

    if (currency !== userSettings.currency) {
      identical = false;
    } else if (country !== userSettings.country) {
      identical = false;
    } else if (units !== userSettings.display_units) {
      identical = false;
    } else if (language !== userSettings.language) {
      identical = false;
    }

    return identical;
  };

  const saveClick = async () => {
    if (isIdentical()) {
      setError(`There's no changes to save.`);
      return;
    }
    if (error) setError(null);
    setDisableBtns(true);

    const onSuccess = [
      () => {
        closeCallback();
      },
    ];

    const onError = [
      () => {
        setError(`Couldn't save your settings. Try refreshing the page.`);
        setDisableBtns(false);
      },
    ];

    const payload = {
      currency: currency,
      country: country,
      display_units: units,
      language: language,
    };

    await i18n.changeLanguage(language);
    dispatch(saveUserSettings(payload, onSuccess, onError));
  };

  const cancelClick = () => {
    closeCallback();
  };

  return (
    <StyledUserSettings>
      <h5>Your Regional Settings</h5>

      <p>Currency *</p>

      <ListDropdown
        options={[
          {key: 'GBP', label: 'GBP (£)'},
          {key: 'USD', label: 'USD ($)'},
          {key: 'EUR', label: 'EUR (€)'},
          {key: 'CAD', label: 'CAD (C$)'},
          {key: 'AUD', label: 'AUD (A$)'},
        ]}
        selected={currency}
        type="button"
        title="Select currency"
        callback={setCurrency}
        wide
      />

      <p>Country *</p>

      <ListDropdown
        options={configs.countries}
        selected={country}
        type="button"
        title="Select country"
        callback={setCountry}
        wide
        maxHeight={350}
      />

      <p>Display Units *</p>

      <ListDropdown
        options={[
          {key: 'cm', label: 'Centimetres'},
          {key: 'in', label: 'Inches'},
        ]}
        selected={units}
        type="button"
        title="Select units"
        callback={setUnits}
        wide
      />

      <p>Language *</p>

      <ListDropdown
        options={[
          {key: 'en-us', label: 'American English'},
          {key: 'en-gb', label: 'British English'},
        ]}
        selected={language}
        type="button"
        title="Select language"
        callback={setLanguage}
        wide
      />

      {error && <StyledErrorMsg>{error}</StyledErrorMsg>}

      <StyledButtonContainer>
        <StyledCancelButton onClick={cancelClick} disabled={disableBtns} style="secondary">
          Cancel
        </StyledCancelButton>

        <CtaButton onClick={saveClick} disabled={disableBtns}>
          Save changes
        </CtaButton>
      </StyledButtonContainer>
    </StyledUserSettings>
  );
};

export default UserSettings;
