import React, {ReactElement, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Props, {Option} from './typings';
import {
  StyledDropdownContainer,
  StyledDropdownHeader,
  StyledDropdownListContainer,
  StyledDropdownList,
  StyledListItem,
  StyledSearchContainer,
  StyledNoSearchResultsMsg,
  StyledSearchCounter,
} from './styles';
import Icon from '@/atoms/Icon/Icon';
import colors from '@/theme/constants/colors';
import useOutsideClick from '@/hooks/use-outside-click/use-outside-click';
import constantsFactory from '@/utils/constants';
import {newKeyboardEvent} from '@/utils/helpers/event';

const {DATA_TEST_ID} = constantsFactory();
const openKeys = ['Enter', 'ArrowUp', 'ArrowDown'];

const ListDropdown = ({
  options,
  selected,
  title,
  type,
  dropdownPosition = 'left',
  rightPadding,
  tick = true,
  maxHeight,
  searchable = false,
  wide,
  callback,
}: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const [focusedOption, setFocusedOption] = useState(selected);
  const selectedIndex = useRef(-1);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const dropdownOptions = useMemo(
    () => (!!filteredOptions?.length ? filteredOptions : options),
    [filteredOptions, options],
  );
  const optionsRefs = useRef<{[index: number]: HTMLElement | null}>({});
  const [showNoResultsMsg, setShowNoResultsMsg] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const dropdownMaxHeight = useRef<number | undefined>(undefined);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const dropdownHeaderRef = useRef<HTMLDivElement | null>(null);
  const lastCursorPos = useRef({
    x: 0,
    y: 0,
  });

  const toggleState = () => setIsOpen(!isOpen);

  const closeDropdown = () => {
    setIsOpen(false);
    resetListSelection();
    resetSearch();
  };

  const resetListSelection = () => {
    selectedIndex.current = -1;
    setFocusedOption(selected);
  };

  const resetSearch = () => {
    setFilteredOptions([]);
    setShowNoResultsMsg(false);
    optionsRefs.current = {};
    setInputValue('');
  };

  const dropdownRef = useOutsideClick(closeDropdown, isOpen);

  const dropdownKeyPress = (e: React.KeyboardEvent) => {
    const isLetter = /^[\p{L}]+$/u.test(e.key);
    const isSpecialKey = [
      'Shift',
      'Control',
      'Alt',
      'Escape',
      'CapsLock',
      'Meta',
      'Backspace',
      'Enter',
      'Tab',
    ].includes(e.key);

    if (isOpen) {
      if (e.key === 'Escape') {
        e.preventDefault();

        closeDropdown();
      } else if (e.key === 'Enter' && selectedIndex.current !== -1) {
        e.preventDefault();

        const key = dropdownOptions[selectedIndex.current].key;
        onOptionClicked(key);
      } else if (e.key === 'Enter' && selectedIndex.current === -1) {
        e.preventDefault();

        closeDropdown();
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();

        if (selectedIndex.current < dropdownOptions.length - 1) {
          selectedIndex.current++;
        } else {
          selectedIndex.current = 0;
        }

        setFocusedOption(dropdownOptions[selectedIndex.current].key);
        scrollOptions(e);
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();

        if (selectedIndex.current > 0) {
          selectedIndex.current--;
        } else {
          selectedIndex.current = dropdownOptions.length - 1;
        }

        setFocusedOption(dropdownOptions[selectedIndex.current].key);
        scrollOptions(e);
      } else if (isLetter && searchable && !isSpecialKey) {
        e.preventDefault();

        searchRef.current?.focus();
        searchRef.current?.dispatchEvent(newKeyboardEvent(e));
        optionsRefs.current = {};
        setInputValue((prevValue) => prevValue + e.key);
      } else if (e.key === 'Tab') {
        closeDropdown();
      }
    } else {
      if (openKeys.includes(e.key)) {
        e.preventDefault();

        toggleState();
      }
    }
  };

  const scrollOptions = (e: React.KeyboardEvent) => {
    const behavior = e.repeat ? 'auto' : 'smooth'; // Use 'auto' for repeated presses

    if (
      optionsRefs.current.hasOwnProperty(selectedIndex.current) &&
      optionsRefs.current[selectedIndex.current]
    ) {
      optionsRefs.current[selectedIndex.current]?.scrollIntoView({
        behavior,
        block: 'nearest',
        inline: 'start',
      });
    }
  };

  const onSearchKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      resetListSelection();
      dropdownHeaderRef.current?.focus();
      dropdownHeaderRef.current?.dispatchEvent(newKeyboardEvent(e));
    }
  };

  const onOptionClicked = (key: string) => {
    closeDropdown();
    callback(key);
  };

  const onOptionHover = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    if (e.screenX !== lastCursorPos.current.x || e.screenY !== lastCursorPos.current.y) {
      lastCursorPos.current.x = e.screenX;
      lastCursorPos.current.y = e.screenY;
      if (focusedOption !== dropdownOptions[index].key) {
        selectedIndex.current = index;
        setFocusedOption(dropdownOptions[index].key);
      }
    }
  };

  const calcMaxHeight = useCallback((node: HTMLDivElement) => {
    if (!node || maxHeight) return undefined;

    dropdownMaxHeight.current = node.getBoundingClientRect().top;
  }, []);

  const getTitle = (): string => {
    if (!title) return '';

    if (selected) {
      const focusedOption = options.find((option) => option.key === selected);
      return focusedOption ? focusedOption.label : title;
    } else {
      return title;
    }
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    optionsRefs.current = {};
    setInputValue(e.target.value);
  };

  useEffect(() => {
    filterOptions();
  }, [inputValue]);

  const filterOptions = () => {
    const sanitizedInputValue = inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`^${sanitizedInputValue}`, 'i');
    const results = options.filter((option) => regex.test(option.label));
    setFilteredOptions(results);
    if (showNoResultsMsg !== !results.length) setShowNoResultsMsg(!results.length);
  };

  return (
    <StyledDropdownContainer ref={dropdownRef} $wide={wide} role="combobox" aria-expanded={isOpen}>
      <StyledDropdownHeader
        $opened={isOpen}
        $type={type}
        $selected={!!selected}
        onClick={toggleState}
        onKeyDown={dropdownKeyPress}
        $wide={wide}
        role="button"
        tabIndex={0}
        ref={dropdownHeaderRef}
      >
        {type === 'icon' ? (
          <Icon type="ellipsis-vertical" color={colors.darkBlue100} fontSize={18} />
        ) : (
          getTitle()
        )}
      </StyledDropdownHeader>

      {isOpen && (
        <StyledDropdownListContainer
          ref={calcMaxHeight}
          data-testid={DATA_TEST_ID.DROPDOWN}
          $position={dropdownPosition}
          $maxHeight={maxHeight}
          $calculatedHeight={dropdownMaxHeight.current}
          role="listbox"
        >
          {searchable && (
            <StyledSearchContainer>
              <input
                ref={searchRef}
                value={inputValue}
                onChange={onSearchChange}
                onKeyDown={onSearchKeyPress}
                placeholder={'Type for quick search...'}
              />

              {!showNoResultsMsg && (
                <StyledSearchCounter>{`Showing ${dropdownOptions.length} result${
                  dropdownOptions.length > 1 ? 's' : ''
                }`}</StyledSearchCounter>
              )}

              {showNoResultsMsg && <StyledNoSearchResultsMsg>No results</StyledNoSearchResultsMsg>}
            </StyledSearchContainer>
          )}
          {!showNoResultsMsg && (
            <StyledDropdownList>
              {dropdownOptions.map((option, index) => (
                <StyledListItem
                  ref={(ref) => (optionsRefs.current[index] = ref)}
                  $rightPadding={rightPadding}
                  $tick={tick}
                  $selected={selected === option.key}
                  $hovered={focusedOption === option.key}
                  onClick={() => onOptionClicked(option.key)}
                  onMouseMove={(e) => {
                    onOptionHover(e, index);
                  }}
                  key={option.key}
                >
                  {option.label}
                </StyledListItem>
              ))}
            </StyledDropdownList>
          )}
        </StyledDropdownListContainer>
      )}
    </StyledDropdownContainer>
  );
};

export default ListDropdown;
