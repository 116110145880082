import {css} from 'styled-components';
import colors from '../constants/colors';

const scrollbar = (color) => css`
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    background: transparent;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${color};
  }
  &::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: transparent;
  }
`;

const primary = scrollbar(colors.red100);

const secondary = scrollbar(colors.darkBlue25);

const hide = css`
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export default {
  primary,
  secondary,
  hide,
};
